.sidebar {
  @apply w-64 flex-shrink-0 overflow-auto h-full bg-white border-r border-gray-200 p-6 flex flex-col space-y-6;
}

.sidebar-link {
  @apply flex items-center rounded-lg py-3 px-4 hover:bg-[#F3F3FF] font-medium space-x-3 text-gray-700;
}

.sidebar-link.is-hover {
  @apply bg-[#F3F3FF] border border-[#E1E1F4];
}

.sidebar-icon {
  @apply w-5 h-5 text-[#00C386];
}

.sidebar-link.active {
  @apply !bg-dark text-white shadow-sm;
}

.sidebar-link.active .sidebar-icon {
  @apply !text-white;
}

/* For mobile only */
@media (max-width: 768px) {
  .sidebar-overlay {
    @apply fixed z-10 top-0 left-0 w-full h-full bg-black bg-opacity-60 opacity-0 invisible transition-all;
  }
  .sidebar {
    @apply absolute top-0 left-0 pt-20 h-full z-10 transition-transform transform-gpu -translate-x-full;
  }
  .sidebar-active .sidebar {
    @apply translate-x-0;
  }
  .sidebar-active .sidebar-overlay {
    @apply !opacity-100 !visible;
  }
}
