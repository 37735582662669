/* Datepicker custom button */
.datepicker-button {
  @apply whitespace-nowrap inline-flex items-center rounded-md bg-[#F4F7FC] py-2 w-full relative cursor-pointer;
}

.datepicker-button-text {
  @apply text-sm font-medium text-gray-900 text-center;
}

.datepicker-button-chevron {
  @apply text-gray-900 text-xl absolute right-4 top-1/2 transform -translate-y-1/2 w-10;
}

/* Popper */
.datepicker .react-datepicker {
  @apply border border-gray-200 rounded-md;
}

/* Popper triangle */
.datepicker[data-placement^='bottom'] .react-datepicker__triangle,
.datepicker[data-placement^='top'] .react-datepicker__triangle {
  @apply !-left-3;
}

.datepicker[data-placement^='bottom'] .react-datepicker__triangle::before {
  @apply !border-b-gray-200;
}

.datepicker[data-placement^='bottom'] .react-datepicker__triangle::after {
  @apply !border-b-[#F4F3FF];
}

.datepicker[data-placement^='top'] .react-datepicker__triangle::before {
  @apply !border-t-gray-200;
}

.datepicker[data-placement^='top'] .react-datepicker__triangle::after {
  @apply !border-b-gray-200;
}

/* Popper header */
.datepicker .react-datepicker__header {
  @apply bg-[#F4F3FF] border-b border-gray-200;
}

/* Popper month */
.datepicker .react-datepicker__current-month {
  @apply text-gray-900 font-sans font-semibold;
}

/* Popper arrows */
.datepicker .react-datepicker__navigation {
  @apply top-2;
}

.datepicker .react-datepicker__navigation-icon::before {
  @apply border-gray-900;
}

.datepicker .react-datepicker__navigation:hover *::before {
  @apply border-gray-900;
}

/* Popper days */
.datepicker .react-datepicker__day-name {
  @apply text-black font-sans font-medium;
}

.datepicker .react-datepicker__day {
  @apply text-black font-sans outline-none;
}

.datepicker .react-datepicker__day--selected {
  @apply bg-blue-500 text-white;
}

.datepicker .react-datepicker__day--selected:hover {
  @apply bg-blue-600;
}

.datepicker .react-datepicker__day--keyboard-selected {
  @apply bg-transparent text-black;
}

/* Popper outside month */
.datepicker .react-datepicker__day--outside-month {
  @apply text-gray-500;
}
