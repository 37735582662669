@import 'tailwindcss/base';
body {
  @apply text-gray-900;
}

/* Components */
@import 'tailwindcss/components';
@import './components/card.css';
@import './components/button.css';
@import './components/review-button.css';
@import './components/datepicker.css';

/* Layouts */
@import './layouts/sidebar.css';
@import './layouts/navbar.css';

@import 'tailwindcss/utilities';
