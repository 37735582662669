.button {
  @apply !bg-blue-500 hover:!bg-blue-600 transition-colors text-white font-medium py-2.5 px-5 rounded-md outline-none border border-transparent whitespace-nowrap inline-flex justify-center items-center space-x-1 disabled:opacity-60 disabled:cursor-not-allowed;
}

.button.dark,
.button.button-dark {
  @apply !bg-gray-900 hover:!bg-gray-800;
}
.button.success,
.button.button-success {
  @apply !bg-[#00C386] hover:!bg-[#00c385e5];
}

.button.outline,
.button.button-outline {
  @apply !bg-white  !border-gray-300 hover:!bg-gray-50 !text-gray-700;
}

.button.sm {
  @apply text-sm py-2;
}

.button:disabled {
  @apply !bg-gray-400 hover:!bg-gray-400;
}
