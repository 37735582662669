.write-review-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background-image: url('../../images/write-review-bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 6px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.write-review-button span {
  display: inline-block;
  padding-right: 8px;
  border-right: 1px solid #fff;
  margin-right: 8px;
}

.write-review-icon {
  width: 40px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.write-review-icon > * + * {
  margin-left: 4px;
}
